import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const RestrictionsPage = () => (
  <Layout>
    <Seo title="Use Restrictions | Clove" />

    <div className={"relative bg-white py-16"}>
      <div className={"mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl text-content"}>
        <h1 className="heading-primary">Use Restrictions</h1>

        <p>
          <strong>Last updated: June 26, 2020</strong>
        </p>

        <h3>Use Restrictions</h3>
        <p>
          At Clove, we recognize that technology is an amplifier: it can enable
          the helpful and the harmful. That’s why we’ve established this policy.
          If you have an account with any of our products, you can’t use them
          for any of the restricted purposes listed below. If we find out you
          are, we will take action.
        </p>

        <h3>Restricted purposes</h3>
        <ul>
          <li>
            Child exploitation, sexualization, or abuse: We don’t tolerate any
            activities that create, disseminate, or otherwise cause child abuse.
            Keep away and stop. Just stop.
          </li>
          <li>
            Doxing: If you are using Clove products to share other peoples’
            private personal information for the purposes of harassment, we
            don’t want anything to do with you.
          </li>
          <li>
            Infringing on intellectual property: You can’t use Clove products to
            make or disseminate work that uses the intellectual property of
            others beyond the bounds of fair use.
          </li>
          <li>
            Malware or spyware: Code for good, not evil. If you are using our
            products to make or distribute anything that qualifies as malware or
            spyware — including remote user surveillance — begone.
          </li>
          <li>
            Phishing or otherwise attempting fraud: It is not okay to lie about
            who you are or who you affiliate with to steal from, extort, or
            otherwise harm others.
          </li>
          <li>
            Violence, or threats thereof: If an activity qualifies as violent
            crime in the United States or where you live, you may not use Clove
            products to plan, perpetrate, or threaten that activity.
          </li>
          <li>
            We’ve outlined these restrictions to be clear about what we won’t
            stand for. That said, this list is by no means exhaustive. We will
            make changes over time.
          </li>
        </ul>
        <p></p>

        <h3>How to report abuse</h3>
        <p>
          See someone using a Clove product for one of the restricted purposes?
          Let us know by emailing hello@clovecx.com. If you’re not 100% sure,
          report it anyway.
        </p>
        <p>
          Please share as much as you are comfortable with about the account,
          the content or behavior you are reporting, and how you found it.
          Sending us a URL or screenshots is super helpful. If you need a secure
          file transfer, let us know and we will send you a link. We will not
          disclose your identity to anyone associated with the reported account.{" "}
        </p>
        <p>
          Someone on our team will respond within two business days to let you
          know we’ve begun investigating. We will also let you know the outcome
          of our investigation (unless you ask us not to or we are not allowed
          to under law).
        </p>

        <p>
          <strong>
            *This policy and process applies to any product created and owned by
            Clove, including Customer Hub.
          </strong>{" "}
        </p>

        <p>
          Adapted from the{" "}
          <a href="https://github.com/basecamp/policies">
            Basecamp open-source policies
          </a>{" "}
          / <a href="https://creativecommons.org/licenses/by/4.0/">CC BY 4.0</a>
        </p>
      </div>
    </div>
  </Layout>
)

export default RestrictionsPage
